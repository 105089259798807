angular.module('app.controllers').controller('EmptyCtrl', ['$scope', '$location'].append(function(self, $location) {
  console.log("EmptyCtrl");
})).controller('ApplicationCtrl', [
  '$scope',
  '$rootScope',
  'BookmarkService',
  function(self,
  root,
  BookmarkService) {
    self.exhibitors = [];
    self.articles = [];
    self.itemIsBookmarked = function(id) {
      return BookmarkService.hasItem(id);
    };
    self.toggleItemBookmark = function(id) {
      if (BookmarkService.hasItem(id)) {
        return BookmarkService.removeItem(id);
      } else {
        return BookmarkService.addItem(id);
      }
    };
    return self.openProductModal = function(item) {
      return self.$emit('openProductModal',
  item);
    };
  }
]);
