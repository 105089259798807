angular.module('app.controllers')
  .controller 'EmptyCtrl', ['$scope', '$location'].append (self, $location) -> 
    console.log "EmptyCtrl"
    return
  .controller('ApplicationCtrl', [
    '$scope','$rootScope', 'BookmarkService',
    (self, root, BookmarkService) ->
      self.exhibitors = []
      self.articles = []

      self.itemIsBookmarked = (id) -> BookmarkService.hasItem(id)

      self.toggleItemBookmark = (id) ->
        if (BookmarkService.hasItem(id))
          BookmarkService.removeItem(id)
        else
          BookmarkService.addItem(id)

      self.openProductModal = (item) ->
        self.$emit('openProductModal', item)
  ])